// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-contact-page-js": () => import("/opt/build/repo/src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-info-page-js": () => import("/opt/build/repo/src/templates/info-page.js" /* webpackChunkName: "component---src-templates-info-page-js" */),
  "component---src-templates-tags-js": () => import("/opt/build/repo/src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-countries-argentina-js": () => import("/opt/build/repo/src/pages/countries/argentina.js" /* webpackChunkName: "component---src-pages-countries-argentina-js" */),
  "component---src-pages-countries-australia-js": () => import("/opt/build/repo/src/pages/countries/australia.js" /* webpackChunkName: "component---src-pages-countries-australia-js" */),
  "component---src-pages-countries-canada-js": () => import("/opt/build/repo/src/pages/countries/canada.js" /* webpackChunkName: "component---src-pages-countries-canada-js" */),
  "component---src-pages-countries-czech-republic-js": () => import("/opt/build/repo/src/pages/countries/czech-republic.js" /* webpackChunkName: "component---src-pages-countries-czech-republic-js" */),
  "component---src-pages-countries-denmark-js": () => import("/opt/build/repo/src/pages/countries/denmark.js" /* webpackChunkName: "component---src-pages-countries-denmark-js" */),
  "component---src-pages-countries-estonia-js": () => import("/opt/build/repo/src/pages/countries/estonia.js" /* webpackChunkName: "component---src-pages-countries-estonia-js" */),
  "component---src-pages-countries-mexico-js": () => import("/opt/build/repo/src/pages/countries/mexico.js" /* webpackChunkName: "component---src-pages-countries-mexico-js" */),
  "component---src-pages-countries-poland-js": () => import("/opt/build/repo/src/pages/countries/poland.js" /* webpackChunkName: "component---src-pages-countries-poland-js" */),
  "component---src-pages-countries-spain-js": () => import("/opt/build/repo/src/pages/countries/spain.js" /* webpackChunkName: "component---src-pages-countries-spain-js" */),
  "component---src-pages-countries-uk-js": () => import("/opt/build/repo/src/pages/countries/uk.js" /* webpackChunkName: "component---src-pages-countries-uk-js" */),
  "component---src-pages-countries-usa-js": () => import("/opt/build/repo/src/pages/countries/usa.js" /* webpackChunkName: "component---src-pages-countries-usa-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tags-index-js": () => import("/opt/build/repo/src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

